import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Home from './screens/Home'
import { useEffect, useState } from 'react'
import { View } from 'react-native'
import Tos from './screens/Tos'
import About from './screens/About'
import Help from './screens/Help'
import NotFound from './screens/NotFound'
import Refer from './screens/Refer'
import { appStoreListing, schools } from './utils/constants'

const ExternalRedirect = ({ url, }: { url: string, }) => {
  useEffect(() => {
    window.location.href = url
  }, [url])
  return null
}

export enum Screens {
  Home = '/',
  About = '/about',
  Help = '/help',
  Tos = '/tos',
  Refer = '/ref',
  NotFound = '*',
}

function App() {
  const [windowDimensions, setWindowDimensions] = useState<Array<number>>([0, 0])
  const mobile = windowDimensions[0] < 768

  return (
    <>
      <Router>
        <Routes>
          <Route path={Screens.Home} element={<Home mobile={mobile} />} />
          <Route path={Screens.About} element={<About mobile={mobile} />} />
          <Route path={Screens.Help} element={<Help mobile={mobile} />} />
          <Route path={Screens.Tos} element={<Tos mobile={mobile} />} />
          <Route path={Screens.Refer} element={<Refer mobile={mobile} />} />
          <Route path={Screens.NotFound} element={<NotFound />} />
          {schools.map(school =>
            <Route key={school} path={`/${school}`} element={<Navigate to={`/?${school}`} replace />} />
          )}
          <Route path='/app' element={<ExternalRedirect url={appStoreListing} />} />
        </Routes>
      </Router>
      <View
        onLayout={(event) => setWindowDimensions([event.nativeEvent.layout.width, event.nativeEvent.layout.height])}
        style={{position: 'absolute', zIndex: -1, width: '100%', height: '100%',}}
      />
    </>
  )
}

export default App
